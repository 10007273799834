<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">수리일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range v-model="searchFilterOptions.dateRange"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">장비구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchEqpDiv"
                    v-model="searchFilterOptions.eqpDiv"
                    :dataSource="commonCodeOptions.eqpDiv"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">장비명</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchEqpName" v-model="searchFilterOptions.eqpName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>

    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
import moment from "moment/moment";
import {getFormattedDate} from "@/utils/date";
import StockEquipmentStat from "@/api/v2/StockManage/StockEquipmentStat";
import StockCommon from "@/api/v2/StockManage/StockCommon";

export default {
  name: "EquipmentRepairStatus",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {
    ReportView,
    ErpButton,
    EjsGridWrapper,
    InputText,
    InputDateRange,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //조회 필드
      searchFilterOptions: {
        eqpName: "",
        eqpDiv: "",
        dateRange: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
      },

      //드롭다운 코드
      commonCodeOptions: {
        wrhousData: [],
        eqpDiv: commonCodesGetCommonCode("EQP_DIV",false,true,"",""),
        eqpChckPart: commonCodesGetCommonCode("EQP_CHCK_PART",false,true,"",""),
        eqpRepairPart: commonCodesGetCommonCode("EQP_REPAIR_PART",false,true,"",""),
        eqpDefectRegn: commonCodesGetCommonCode("EQP_DEFECT_REGN",false,true,"",""),
        fields: { text: "comName", value: "comCode" },
      },

      //그리드 필드
      gridField: {
        title: "수리목록",
        count : 0,
        dataSource: [],
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          false
      );
    },
    initGrid() {
      return {
        columns: [
          { field: "repairId",       textAlign: 'center', allowEditing: false, width: 70,   visible: true,  type: "string",  headerText: "수리번호", isPrimaryKey: true, },
          { field: "repairDate",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "수리일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "eqpDiv",         textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "장비구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.eqpDiv, },
          { field: "eqpCode",        textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "장비코드", },
          { field: "eqpName",        textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "장비명", },
          { field: "chckPart",       textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "점검부", isCommonCodeField: true, dataSource: this.commonCodeOptions.eqpChckPart, },
          { field: "repairDiv",      textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "수리구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.eqpRepairPart, },
          { field: "defectRegn",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "고장부위", isCommonCodeField: true, dataSource: this.commonCodeOptions.eqpDefectRegn, },
          { field: "remarks",        textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",  headerText: "수리내역", },
          { field: "wrhousCode",     textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "출고창고", isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          { field: "suppliesName",   textAlign: 'left',   allowEditing: false, width: 150,  visible: true,  type: "string",  headerText: "저장품", },
          { field: "unit",           textAlign: 'center', allowEditing: false, width: 100,   visible: true,  type: "string",  headerText: "단위", },
          { field: "qty",            textAlign: 'right',  allowEditing: false, width: 100,   visible: true,  type: "string",  headerText: "출고수량", isNumericType: true, },
          { field: "dtlRemarks",     textAlign: 'center', allowEditing: false, width: 250,  visible: true,  type: "string",  headerText: "비고", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted() {
    this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* 버튼 이벤트 *******************************
    //초기화
    async initData() {
      //창고콤보(권한있는 저장품창고만)--이력이니 삭제된것도
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'N' ,authoFlag:'N',suppliesFlag:'Y'});
    },
    //조회
    async onClickSearch() {
      const args = {
        eqpName: this.searchFilterOptions.eqpName,
        eqpDiv: this.searchFilterOptions.eqpDiv,
        fromDate: this.searchFilterOptions.dateRange.from,
        toDate: this.searchFilterOptions.dateRange.to,
      };

      this.gridField.dataSource = await StockEquipmentStat.getEqpRepairStat(args);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //리포트 출력
    onClickPrint() {
      //
    },
    //endregion
    //region ******************************* 상세 이벤트 *******************************
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 10px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 10px); margin:0}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>
