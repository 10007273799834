import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-equipment-stat';

class StockEquipmentStat {

    /** 장비 수리 현황 조회 */
    async getEqpRepairStat(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/Stat` ,{params});
        return data;
    }
    /** 유류 사용 현황 조회 */
    async getOilUseStatus(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/oil/Stat` ,{params});
        return data;
    }
    /** 장비출역 사용 현황 조회 */
    async getEqpDlivyStatus(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy/Stat` ,{params});
        return data;
    }
}

export default new StockEquipmentStat();